import React, { useEffect, useState } from 'react';
import { Box, Heading, Text } from 'grommet';
import { useTheme } from 'styled-components';
import stocks from '../stocks.json';

const About = () => {
    const [stockList, setStockList] = useState([]);
    const theme = useTheme();
    const blueColor = theme.global.colors.blue;

    useEffect(() => {
        // Sort the stock symbols and names alphabetically by name
        const sortedStocks = [...stocks].sort((a, b) => a.name.localeCompare(b.name));
        setStockList(sortedStocks);
    }, []);

    return (
        <Box pad="medium">
            <Heading margin={{ top: 'none' }}>About Nada Capital</Heading>
            <Box>
                <Heading level="3" margin={{ top: 'none' }}>Stock List</Heading>
                <Box gap="small" direction="row" wrap>
                    {stockList.map((stock, index) => (
                        <Box
                            key={index}
                            pad="small"
                            background="light-3"
                            border={{ color: blueColor, size: 'small' }} // Use theme blue color for border
                            round="small"
                            width="medium" // Adjust width to ensure text fits
                            height="xsmall" // Adjust height to ensure text fits
                        >
                            <Text weight="bold" truncate>{stock.name}</Text>
                            <Text truncate>Symbol: {stock.symbol}</Text>
                            <Text truncate>Market: {stock.market}</Text>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    );
}

export default About;