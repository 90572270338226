import React, { useContext, useEffect } from 'react';
import { Box, Button, Header, Image, Menu, Nav, ResponsiveContext } from 'grommet';
import { Link } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import logo from '../images/Logo-250x82.jpg';

const items = [
  { label: 'Home', loc: "/" },
  { label: 'About', loc: "/about" },
  { label: 'Links', loc: "/links" },
  { label: 'Private', loc: "/private" },
];

const Navigation = () => {
  const size = useContext(ResponsiveContext);
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();

  useEffect(() => {
    if (user) {
      console.log(user);
    }
  }, [user]);

  return (
    <Header round="small" border={{ color: "#000000", size: "medium", style: "solid" }} height="xsmall" fill="horizontal" background={{ color: "#FFFFFF", opacity: "strong" }}>
      <Button plain>
        <Box direction="row" align="center" gap="medium">
          <Box direction="row" gap="xsmall">
            <Image src={logo} alt="Logo" />
          </Box>
        </Box>
      </Button>
      {size === 'small' ? (
        <Menu
          label="Menu"
          items={[
            ...items.map(item => ({
              label: item.label,
              onClick: () => window.location.href = item.loc,
            })),
            isAuthenticated
              ? { label: 'Logout', onClick: () => logout({ returnTo: window.location.origin }) }
              : { label: 'Login', onClick: () => loginWithRedirect() }
          ]}
        />
      ) : (
        <Nav direction="row">
          {items.map(item => (
            <Button key={item.label} as={Link} to={item.loc} label={item.label} />
          ))}
          {isAuthenticated ? (
            <Button label="Logout" onClick={() => logout({ returnTo: window.location.origin })} />
          ) : (
            <Button label="Login" onClick={() => loginWithRedirect()} />
          )}
        </Nav>
      )}
    </Header>
  );
};

export default Navigation;