import React from 'react';
import { Box, Heading, Text, Image } from 'grommet';
import { useAuth0 } from "@auth0/auth0-react";

const Private = () => {
    const { user, isAuthenticated } = useAuth0();

    return (
        <Box pad="medium">
            <Heading margin={{ top: 'none' }}>Private Page</Heading>
            {isAuthenticated ? (
                <Box>
                    <Box width="small" height="small">
                        <Image src={user.picture} alt={user.name} fit="cover" />
                    </Box>
                    <Text>Welcome, {user.name}!</Text>
                    <Text>Email: {user.email}</Text>
                    <Text>You have access to this private page.</Text>
                </Box>
            ) : (
                <Text>You are not authenticated. Please log in to access this page.</Text>
            )}
        </Box>
    );
}

export default Private;