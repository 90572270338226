import React from 'react';
import { Box, Heading, Text } from 'grommet';
import { useAuth0 } from "@auth0/auth0-react";

const GroupPrivate = () => {
    const { user, isAuthenticated } = useAuth0();

    return (
        <Box pad="medium">
            <Heading margin={{ top: 'none' }}>Group Private Page</Heading>
            {isAuthenticated ? (
                <Box>
                    <Text>Welcome to the group private page, {user.name}!</Text>
                    <Text>Your email: {user.email}</Text>
                    <Text>You have access to this page because you are an authenticated user.</Text>
                </Box>
            ) : (
                <Text>You are not authenticated. Please log in to access this page.</Text>
            )}
        </Box>
    );
}

export default GroupPrivate;