import React from 'react';
import { Box, Anchor, Heading, Grid } from 'grommet';
import { useTheme } from 'styled-components';

const Links = () => {
  const theme = useTheme();
  const blueColor = theme.global.colors.blue;

  return (
    <Box pad="medium">
      <Heading margin={{ top: 'none' }}>Links</Heading>

      <Grid
        columns={{ count: 'fit', size: 'medium' }}
        gap="small"
      >
        <Box
          background="light-3"
          align="left"
          pad="small"
          border={{ color: blueColor, size: 'small' }} // Use theme blue color for border
          round="small" // Round the corners of the box
        >
          <Heading level="3" margin={{ top: 'none' }} color="black">Investing</Heading> {/* Set title color to black */}
          <Anchor href="https://www.cnbc.com/" color={blueColor} target="_blank" rel="noopener noreferrer">CNBC</Anchor>
          <Anchor href="https://finchat.io/" color={blueColor} target="_blank" rel="noopener noreferrer">FinChat</Anchor>
          <Anchor href="https://ca.finance.yahoo.com/" color={blueColor} target="_blank" rel="noopener noreferrer">Yahoo Finance</Anchor>
          <Anchor href="https://www.google.com/finance/" color={blueColor} target="_blank" rel="noopener noreferrer">Google Finance</Anchor>
          <Anchor href="https://dividendstrategy.ca/" color={blueColor} target="_blank" rel="noopener noreferrer">Dividend Strategy</Anchor>
          <Anchor href="https://cutthecrapinvesting.com/" color={blueColor} target="_blank" rel="noopener noreferrer">Cut the Crap Investing</Anchor>
          <Anchor href="https://www.bloomberg.com/" color={blueColor} target="_blank" rel="noopener noreferrer">Bloomberg</Anchor>
          <Anchor href="https://www.marketwatch.com/" color={blueColor} target="_blank" rel="noopener noreferrer">MarketWatch</Anchor>
        </Box>

        <Box
          background="light-3"
          align="left"
          pad="small"
          round="small" // Round the corners of the box
          border={{ color: blueColor, size: 'small' }} // Use theme blue color for border
        >
          <Heading level="3" margin={{ top: 'none' }} color="black">React</Heading> {/* Set title color to black */}
          <Anchor href="https://auth0.com/" color={blueColor} target="_blank" rel="noopener noreferrer">Auth0</Anchor>
          <Anchor href="https://reactjs.org/" color={blueColor} target="_blank" rel="noopener noreferrer">React</Anchor>
          <Anchor href="https://redux.js.org/" color={blueColor} target="_blank" rel="noopener noreferrer">Redux</Anchor>
          <Anchor href="https://reactrouter.com/" color={blueColor} target="_blank" rel="noopener noreferrer">React Router</Anchor>
          <Anchor href="https://styled-components.com/" color={blueColor} target="_blank" rel="noopener noreferrer">Styled Components</Anchor>
          <Anchor href="https://grommet.io/" color={blueColor} target="_blank" rel="noopener noreferrer">Grommet</Anchor>
        </Box>

        <Box
          background="light-3"
          align="left"
          pad="small"
          round="small" // Round the corners of the box
          border={{ color: blueColor, size: 'small' }} // Use theme blue color for border
        >
          <Heading level="3" margin={{ top: 'none' }} color="black">Development Tools</Heading> {/* Set title color to black */}
          <Anchor href="https://code.visualstudio.com/" color={blueColor} target="_blank" rel="noopener noreferrer">Visual Studio Code</Anchor>
          <Anchor href="https://github.com/" color={blueColor} target="_blank" rel="noopener noreferrer">GitHub</Anchor>
          <Anchor href="https://www.npmjs.com/" color={blueColor} target="_blank" rel="noopener noreferrer">npm</Anchor>
          <Anchor href="https://yarnpkg.com/" color={blueColor} target="_blank" rel="noopener noreferrer">Yarn</Anchor>
          <Anchor href="https://webpack.js.org/" color={blueColor} target="_blank" rel="noopener noreferrer">Webpack</Anchor>
          <Anchor href="https://babeljs.io/" color={blueColor} target="_blank" rel="noopener noreferrer">Babel</Anchor>
        </Box>
      </Grid>
    </Box>
  );
}

export default Links;