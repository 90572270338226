import React from 'react';
import { Box, Heading, Text } from 'grommet';
import { useAuth0 } from "@auth0/auth0-react";

const Home = () => {
  const { user, isAuthenticated } = useAuth0();
  return (
    <Box pad="medium">
      <Box align="center" justify="center">
        <Heading margin={{ top: 'none' }}>Welcome to Nada Capital</Heading>
      </Box>
      <Box>
        <Text>
          {isAuthenticated ? `Welcome, ${user.name}!` : 'Welcome!'}
        </Text>
        <Text margin={{ top: 'medium' }}>
          Nada Capital is a company. You do not get to see what we do.
        </Text>
        
      </Box>
    </Box>
  );
}

export default Home;