import React from 'react';
import { Box, Text } from 'grommet';
import { useAuth0 } from '@auth0/auth0-react';

const Footer = () => {
  const { user, isAuthenticated } = useAuth0();

  return (
    <Box
      as="footer"
      pad="small"
      background="light-4"
      align="center"
      justify="between"
      direction="row"
    >
      <Text>&copy; {new Date().getFullYear()} Nada Capital</Text>
      {isAuthenticated ? (
        <Text>
          Logged in as: {user.name} ({user.email})
        </Text>
      ) : (
        <Text>Not logged in</Text>
      )}
    </Box>
  );
};

export default Footer;